// @ts-ignore
import { Button, Form, Heading } from 'react-bulma-components'
import Icon from '../utils/Icon'
import Hr from '../utils/Hr'
import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import Query from '../../tools/Query'

type ConnectionStatus = 'offline' | 'online' | 'loading' | 'timeout' | 'invalid_credentials'

export function SettingsAsm() {
	const context = useContext(GlobalContext)
	const { translate, isLightTheme, school, updateSchoolParams, addToast, getActiveUai } = context

	const asmInformations = school.deployment.asmInformations

	const [locationId, setLocationId] = useState<string>(asmInformations?.locationId || '')
	const [isSftp, setIsSftp] = useState(asmInformations?.isSftp)
	const [urlServer, setUrlServer] = useState(asmInformations?.sftpUrl || '')
	const [user, setUser] = useState<string>(asmInformations?.sftpUser || '')
	const [password, setPassword] = useState<string>(asmInformations?.sftpPassword || '')
	const [port, setPort] = useState(asmInformations?.sftpPort || '22')

	const [history, setHistory] = useState<Array<{ pushedAt: string; filename: string }>>(asmInformations?.history || [])

	const [sftpConnectionStatus, setSftpConnectionStatus] = useState<ConnectionStatus>('offline')
	const [showSftpPassword, setShowSftpPassword] = useState(false)

	const [editing, setEditing] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		try {
			pingSftp()
		} catch {}
	}, [])

	function sftpServerIsSetup(): boolean {
		return !!(urlServer && user && password && port)
	}

	function pingSftp() {
		setSftpConnectionStatus('loading')

		setTimeout(() => {
			Query.sftpPing(urlServer, user, password, port, isSftp)
				.then(() => setSftpConnectionStatus('online'))
				.catch(status => {
					const statusMap = {
						401: 'timeout',
						403: 'invalid_credentials',
						400: 'offline',
					}

					// @ts-ignore
					setSftpConnectionStatus(statusMap[status] || 'offline')
				})
		}, 1_000)
	}

	function handleSubmit(e: FormEvent) {
		e.preventDefault()

		if (!editing) {
			setShowSftpPassword(true)
			return setEditing(true)
		}

		setLoading(true)

		const params = {
			deployment: {
				...school?.deployment,
				asmInformations: {
					locationId,
					sftpUrl: urlServer,
					sftpUser: user,
					sftpPassword: password,
					sftpPort: port,
					isSftp,
					history,
				},
			},
		}

		Query.updateParams(context, getActiveUai(), params)
			.then(() => {
				setLoading(false)
				setEditing(false)
				updateSchoolParams(params)
				addToast('Les informations ont bien été enregistrées.', { appearance: 'success' })
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}

	function pushSftpFileToServer(inputEvent: ChangeEvent<HTMLInputElement>) {
		const file = inputEvent.target.files![0]

		if (!file) {
			return addToast("Aucun fichier n'a été sélectionné.", { appearance: 'error' })
		}

		Query.pushSftpFile(file, getActiveUai(), { sftpUrl: urlServer, sftpUser: user, sftpPassword: password, sftpPort: port, isSftp })
			.then(filename => {
				addToast('Le fichier a bien été envoyé.', { appearance: 'success' })
				pushFileToHistory(filename)
			})
			.catch(e => {
				addToast(e, { appearance: 'error' })
			})
	}

	function pushFileToHistory(filename: string) {
		const newHistory = [...history, { pushedAt: new Date().toISOString(), filename }]

		setHistory(newHistory)

		const params = {
			deployment: {
				...school?.deployment,
				asmInformations: {
					locationId,
					sftpUrl: urlServer,
					sftpUser: user,
					sftpPassword: password,
					sftpPort: port,
					isSftp,
					history: newHistory,
				},
			},
		}

		Query.updateParams(context, getActiveUai(), params)
			.then(() => {
				setLoading(false)
				setEditing(false)
				updateSchoolParams(params)
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}

	const connectionMessage = {
		offline: translate('settings.asm.sftp_not_connected'),
		online: translate('settings.asm.sftp_connected'),
		loading: translate('settings.asm.sftp_connect'),
		timeout: translate('settings.asm.sftp_timeout'),
		invalid_credentials: translate('settings.asm.sftp_invalid_credentials'),
	}

	const connectionColor = {
		offline: { background: 'bg-gray-500', text: 'text-gray-500' },
		online: { background: 'bg-green-500', text: 'text-green-500' },
		loading: { background: 'bg-blue-500', text: 'text-blue-500' },
		timeout: { background: 'bg-red-500', text: 'text-red-500' },
		invalid_credentials: { background: 'bg-amber-500', text: 'text-amber-500' },
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className="is-flex" style={{ justifyContent: 'space-between' }}>
				<Heading textColor={!isLightTheme ? 'light' : ''} size={5} style={{ marginBottom: '0' }}>
					ASM / Claris
				</Heading>
				<Button loading={loading} color={editing ? 'success' : 'info'} size={'small'} type={'submit'}>
					<Icon key={editing ? 'save' : 'edit'} icon={editing ? 'fal fa-check' : 'fal fa-money-check-edit'} />
					<span>{editing ? translate('global.save') : translate('global.update')}</span>
				</Button>
			</div>
			<Hr />
			<section>
				<Form.Field>
					<Form.Label>{translate('settings.asm.define_location_id')}</Form.Label>
					<Form.Control iconLeft>
						<Form.Input placeholder="Définissez le location_id pour l'export" type={'text'} name={'locationId'} value={locationId} disabled={!editing} onChange={(e: ChangeEvent<HTMLInputElement>) => setLocationId(e.target.value)} />
						<Icon className="icon is-left" icon={'fal fa-school'} />
					</Form.Control>
				</Form.Field>
				<div className="rounded border pt-4">
					<Form.Field className="px-4">
						<Form.Label>URL Server</Form.Label>
						<Form.Control iconLeft iconRight>
							<Form.Input type={'text'} name={'urlServer'} value={urlServer} disabled={!editing} onChange={(e: ChangeEvent<HTMLInputElement>) => setUrlServer(e.target.value)} />
							<Icon className="icon is-left" icon={'fal fa-server'} />
						</Form.Control>
					</Form.Field>
					<Form.Field className="px-4">
						<Form.Label>Utilisateur</Form.Label>
						<Form.Control iconLeft iconRight>
							<Form.Input placeholder={'user'} type={'text'} name={'user'} value={user} disabled={!editing} onChange={(e: ChangeEvent<HTMLInputElement>) => setUser(e.target.value)} />
							<Icon className="icon is-left" icon={'fal fa-user'} />
						</Form.Control>
					</Form.Field>
					<Form.Field className="px-4">
						<Form.Label>Mot de passe</Form.Label>
						<Form.Control iconLeft iconRight>
							<Form.Input placeholder={'password'} type={showSftpPassword ? 'text' : 'password'} name={'password'} value={password} disabled={!editing} onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} />
							<Icon className="icon is-left" icon={'fal fa-key'} />
							<Icon key={showSftpPassword ? 'show' : 'hidden'} onClick={() => setShowSftpPassword(!showSftpPassword)} className={'icon is-icon-clickable is-right'} icon={showSftpPassword ? 'far fa-eye-slash' : 'far fa-eye'} />
						</Form.Control>
					</Form.Field>
					<div className={'flex p-2'}>
						<Form.Field className="px-4">
							<Form.Label>Port</Form.Label>
							<Form.Control iconLeft iconRight>
								<Form.Input type={'text'} name={'port'} value={port} disabled={!editing} onChange={(e: ChangeEvent<HTMLInputElement>) => setPort(e.target.value)} />
								<Icon className="icon is-left" icon={'fal fa-server'} />
							</Form.Control>
						</Form.Field>
						<Form.Field className="px-4">
							<Form.Label>SFTP / FTP</Form.Label>
							<input id="isSftp" type="checkbox" disabled={!editing} className="switch is-rounded is-success is-small" checked={isSftp} name={'isSftp'} onChange={() => setIsSftp(!isSftp)} />
							<label htmlFor="isSftp" />
						</Form.Field>
					</div>
					<p className="flex items-center space-x-2 text-xs px-4">
						<button className="underline has-text-primary italic" type="button" onClick={pingSftp}>
							{translate('settings.asm.sftp_send_ping')}
						</button>
						<span className={`w-1.5 h-1.5 rounded-full ${connectionColor[sftpConnectionStatus].background}`} />
						<span className={connectionColor[sftpConnectionStatus].text}>{connectionMessage[sftpConnectionStatus]}</span>
					</p>

					{sftpServerIsSetup() && (
						<div className={`${sftpConnectionStatus !== 'online' ? 'opacity-50 pointer-events-none' : 'opacity-100'} flex items-center justify-center w-full mt-8 px-4`}>
							<label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
								<div className="flex flex-col items-center justify-center">
									<svg className="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
										<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
									</svg>
									<p className="mb-2 text-sm text-gray-500 ">
										<span className="font-semibold">Cliquez pour envoyer</span> ou glissez-déposez
									</p>
									<p className="text-xs text-gray-500 ">.ZIP</p>
								</div>
								<input id="dropzone-file" onChange={pushSftpFileToServer} accept="application/zip" type="file" className="hidden" />
							</label>
						</div>
					)}

					<div className="mt-8 border-t p-4 bg-gray-50">
						<Heading textColor={!isLightTheme ? 'light' : ''} size={6} style={{ marginBottom: '0' }}>
							Historique des fichiers ASM envoyés
						</Heading>
						{history.reverse().length > 0 ? (
							<ul className="list-disc list-inside pt-4">
								{history.map((file, index) => (
									<li onClick={() => Query.downloadSftpArchive(file.filename)} key={index} className="text-sm has-text-primary cursor-pointer hover:underline marker:text-gray-800">
										{file.filename} <span className="italic text-gray-600">(envoyé le {new Date(file.pushedAt).toLocaleDateString()})</span>
									</li>
								))}
							</ul>
						) : (
							<p className="text-xs pt-4">Aucun fichier envoyé.</p>
						)}
					</div>
				</div>
			</section>
		</form>
	)
}
